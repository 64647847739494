@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700&display=swap");

body {
  margin: 0;
  margin-top: 5%;
  margin-bottom: 5%;
  font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
label {
  margin-top: 5px;
  margin-bottom: 0.1rem;
}
.container-slum {
  max-width: 1440px;
  margin-left: 10%;
  margin-right: 10%;
  margin: 0 auto !important;
}
.title-slum-tally {
  font-weight: bold;
}
.voce-menu {
  color: black;
  text-decoration: underline;
  font-size: 1.2em;
  margin-bottom: 25px;
  line-height: 1.6em;
}
.inserisci-azienda-div {
  width: 50%;
}
.input-slum-tally {
  width: 80%;
  border: 0;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-bottom: 1px solid black;
  height: 25px;
  margin-bottom: 15px;
  outline: none;
}
.input-slum-tally:disabled {
  width: 100%;
}
.input-slum-tally:focus {
  box-shadow: 0 !important;
  border-bottom: 2px solid black;
  width: 100%;
}
.input-slum-tally-error {
  border-bottom: 1px solid red;
  width: 100%;
}
.send-button {
  margin-top: 20px !important;
}
.send-btn {
  color: white;
  height: 40px;
  background-color: black;
  box-shadow: none;
  border: 1px solid black;
  border-radius: 12px;
}
.send-btn:hover {
  background-color: white;
  color: black;
}
.messaggio-successo-slum-tally {
  color: green;
}
.messaggio-errore-slum-tally {
  color: red;
}
.modifica-btn {
  background-color: white;
  color: black;
  box-shadow: none;
  border: 1px solid black;
  width: 120px;
  height: 35px;
  border-radius: 12px;
  margin-left: 10px;
  margin-top: 8px;
}
.visualizza-btn {
  background-color: black;
  color: white;
  box-shadow: none;
  border: 1px solid black;
  width: 120px;
  height: 35px;
  border-radius: 12px;
  margin-top: 8px;
}
.visualizza-richieste-btn {
  background-color: black;
  color: white;
  box-shadow: none;
  border: 1px solid black;
  width: auto;
  height: 35px;
  border-radius: 12px;
  margin-top: 8px;
}
.button-general {
  background-color: green;
  color: white;
  font-size: 1.2em;
}
.scritta-nera {
  color: black;
}
.modifica-btn:hover {
  background-color: black;
  color: white;
  box-shadow: none;
  border: 1px solid black;
  border-radius: 12px;
  margin-left: 10px;
}
.visualizza-btn:hover {
  background-color: white;
  color: black;
  box-shadow: none;
  border: 1px solid black;
  border-radius: 12px;
}
.visualizza-richieste-btn:hover {
  background-color: white;
  color: black;
  box-shadow: none;
  border: 1px solid black;
  border-radius: 12px;
}
.container-nomi-azienda {
  margin: 15px;
  padding: 15px;
  border-radius: 25px;
  border: 1px solid lightgray;
}
.nome-azienda {
  font-size: 1em;
}
.campi-visualizza-dati {
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 5px;
}
.lista-dati-aziende {
  padding-top: 5px;
  padding-left: 10px;
}
.input-visualizza-dati {
  border-bottom: 1px solid black;
  border-top: none;
  border-left: none;
  border-right: none;
}
.send-modifica-btn {
  background-color: white;
  color: black;
  box-shadow: none;
  border: 1px solid black;
  width: 50%;
  border-radius: 12px;
}
.send-modifica-btn:hover {
  background-color: black;
  color: white;
  box-shadow: none;
  border: 1px solid black;
  border-radius: 12px;
}
.logo {
  width: 50%;
  margin-bottom: 15px;
}
.add-btn {
  border-radius: 12px;
  box-shadow: none;
  border: 1px solid black;
  background-color: white;
  margin-top: 15px;
  height: 35px;
  width: 55px;
  font-size: 1.3em;
}
.cerca-azienda-input {
  margin-bottom: 15px;
  border-bottom: 1px solid black;
  border-left: none;
  border-right: none;
  border-top: none;
  width: 80%;
}
.cerca-azienda-input:focus {
  outline: 0;
}
.lista-aziende {
  max-height: 90vh;
  overflow: scroll;
}
.content-support-chat {
  border-bottom: 0px;
  border-radius: 5px;
  overflow-y: scroll;
  width: 100%;
  height: 400px;
  padding-bottom: 15px;
}
.text-area-support-chat {
  border: 1px solid #e0e0e0;
  padding: 15px;
  border-radius: 0px;
  width: 100%;
  height: 330px;
  margin-top: 20px;
}
.textarea-slum {
  height: 230px;
  width: 100%;
  border-bottom: 1px solid black;
  -webkit-box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 1);
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 1);
  border: 0px;
  box-shadow: none;
}
.textarea-slum:focus {
  outline: none !important;
}
.row-received-message {
  margin-top: 15px;
  width: 100%;
  padding-left: 35px;
  padding-top: 5px;
}
.received-message {
  padding: 25px;
  border-radius: 0px 20px 20px 20px;
  background-color: #f0f8ff;
}
.send-message {
  padding: 25px;
  border-radius: 20px 0px 20px 20px;
  background-color: #f0f0f0;
}
.visualizza-btn-chat {
  background-color: black;
  color: white;
  box-shadow: none;
  border: 1px solid black;
  width: 150px;
  height: 35px;
  border-radius: 12px;
  margin-top: 8px;
}
.visualizza-btn-chat:hover {
  background-color: white;
  color: black;
  box-shadow: none;
  border: 1px solid black;
  border-radius: 12px;
}

.loader-chat {
  height: 50%;
}
.centrato-verticale-div {
  margin: 0;
  position: absolute;
  top: 50%;
  width: 270px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow: "hidden";
}
.testo-tally-viola {
  background-color: white;
  color: black;
  background-color: white;
  text-align: left;
  background-color: white;
  margin-bottom: 0;
  font-size: 1.2em;
}
.input-tally {
  width: 100%;
  box-shadow: none;
  border: 1px solid lightgray !important;
  border-radius: 15px;
  padding: 15px;
  border: none;
  margin-bottom: 15px;
}
.input-tally:focus {
  width: 100%;
  border: 0;
  border-bottom: 2px solid black;
  outline: none;
  background-color: white;
}
.testo-tally-grigio {
  text-align: left;
  text-decoration: underline;
  font: Light 15px/22px Be Vietnam;
  letter-spacing: 0;
  color: #222222;
  font-size: 0.8em;
  opacity: 1;
}
@media only screen and (max-width: 600px) {
  .centrato-verticale-div {
    margin: 0;
    position: absolute;
    overflow: "hidden";
  }
}
.backup-btn {
  color: black;
  height: 30px;
  background-color: white;
  box-shadow: none;
  border: 1px solid black;
  width: 50%;
  border-radius: 12px;
  margin-left: -5px;
  margin-top: 5px;
}
.backup-btn:hover {
  background-color: black;
  color: white;
}
.logo-view-campo-azienda {
  height: 90px;
}
.login {
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: hidden;
}
.logo-login {
  width: 150px;
  margin-bottom: 10%;
}
.error-login {
  color: red;
  font-size: 1em;
  margin-top: 10px;
}
.button-revisione {
  margin-top: 5px;
}
.button-effettuato {
  margin-top: 8px;
}
.msg-operazione-eseguita {
  color: green;
}
.row-chat {
  padding-top: 53px;
}
.btn-logout {
  margin-bottom: 10px;
  border: none;
  background-color: white;
  font-size: 0.9em;
  padding: 0;
}
.received-msg-nome-azienda {
  font-weight: bold;
  width: 40%;
  text-align: left;
  padding-left: 8px;
}
.received-msg-data-invio {
  width: 60%;
  text-align: right;
  font-size: 0.8em;
  padding-right: 8px;
}
.received-msg-testo {
  max-width: 100%;
  word-break: break-all;
}
.send-msg-data-invio {
  width: 60%;
  text-align: left;
  font-size: 0.8em;
  padding-left: 8px;
}
.send-msg-tally {
  font-weight: bold;
  width: 40%;
  text-align: right;
  padding-right: 8px;
}
.send-msg-testo {
  max-width: 100%;
  word-break: break-all;
}
.link-download-db {
  margin-top: 5px;
}
 .green:hover, .gray:hover {
  color: white !important;
  zoom: 1.0;
}
